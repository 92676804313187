<template>
  <div class="container-app">
    <z-header/>
    <div class="container-app-bottom-con">
      <z-left/>
      <div class="parent">
        <div class="child">
          <div v-for="(item, index) in listData" :key="index">
            <detail-cell :data-input="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ZHeader from "@/components/zHeader"
import ZLeft from "@/components/zLeft"
import DetailCell from "@/components/detailCell"
import config from "@/utils/config";

export default {
  name: 'YuanYuZhouView',
  components: {DetailCell, ZLeft, ZHeader},
  data() {
    return {
      listData: [
        {
          label: '元宇宙',
          children: [
            {
              label: '军工数字孪生',
              type: 2,
              url: config.fuJian + '元宇宙/军工数字孪生/军工数字孪生.mp4',
              img: config.fuJian + '元宇宙/军工数字孪生/军工数字孪生-封面.jpg'
            },
            {
              label: '虚拟车展',
              type: 2,
              url: config.fuJian + '元宇宙/虚拟车展/虚拟车展.mp4',
              img: config.fuJian + '元宇宙/虚拟车展/虚拟车展-封面.jpg'
            },
            {
              label: '虚拟展馆1',
              type: 2,
              url: config.fuJian + '元宇宙/虚拟展馆1/虚拟展馆1.mp4',
              img: config.fuJian + '元宇宙/虚拟展馆1/虚拟展馆1-封面.jpg'
            },
            {
              label: '虚拟展馆2',
              type: 2,
              url: config.fuJian + '元宇宙/虚拟展馆2/虚拟展馆2.mp4',
              img: config.fuJian + '元宇宙/虚拟展馆2/虚拟展馆2-封面.jpg'
            },
            {
              label: '虚拟展馆建造',
              type: 2,
              url: config.fuJian + '元宇宙/虚拟展馆建造/虚拟展馆建造.mp4',
              img: config.fuJian + '元宇宙/虚拟展馆建造/虚拟展馆建造-封面.jpg'
            },
            {
              label: 'IPverse',
              type: 2,
              url: config.fuJian + '元宇宙/IPverse/IPverse.mp4',
              img: config.fuJian + '元宇宙/IPverse/IPverse-封面.jpg'
            }
          ]
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
.span-txt {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.span-img {
  width: 140px;
  height: 70px;
  object-fit: cover;
  border-radius: 6px;
}

</style>
