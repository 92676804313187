import {createWebHistory, createRouter} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import YuanYuZhouView from "@/views/YuanYuZhouView"
import GongZuoChangJingView from "@/views/GongZuoChangJingView"
import NeiBuShiXunView from "@/views/NeiBuShiXunView"
import ShuZiLuanShengView from "@/views/ShuZiLuanShengView"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/YuanYuZhouView',
    name: 'YuanYuZhouView',
    component: YuanYuZhouView
  },
  {
    path: '/GongZuoChangJingView',
    name: 'GongZuoChangJingView',
    component: GongZuoChangJingView
  },
  {
    path: '/NeiBuShiXunView',
    name: 'NeiBuShiXunView',
    component: NeiBuShiXunView
  },
  {
    path: '/ShuZiLuanShengView',
    name: 'ShuZiLuanShengView',
    component: ShuZiLuanShengView
  }
]

const router = new createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
