<template>
  <div class="container-app">
    <z-header/>
    <div class="container-app-bottom-con">
      <z-left/>
      <div class="parent">
        <div class="child">
          <div v-for="(item, index) in listData" :key="index">
            <detail-cell :data-input="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ZHeader from "@/components/zHeader"
import ZLeft from "@/components/zLeft"
import DetailCell from "@/components/detailCell"
import config from "@/utils/config";

export default {
  name: 'GongZuoChangJingView',
  components: {DetailCell, ZLeft, ZHeader},
  data() {
    return {
      listData: [
        {
          label: '工作场景',
          children: [
            {
              label: '元宇宙场景建设（实拍）',
              type: 2,
              url: config.fuJian + '工作场景/元宇宙制作过程（天体科技工作场景）.mp4',
              img: config.fuJian + '工作场景/元宇宙制作过程（天体科技工作场景）.jpg'
            }
          ]
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
.span-txt {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.span-img {
  width: 140px;
  height: 70px;
  object-fit: cover;
  border-radius: 6px;
}

</style>
