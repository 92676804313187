<template>
  <div class="container-app">
    <z-header/>
    <div class="container-app-bottom-con">
      <z-left/>
      <div class="parent">
        <div class="child">
          <div v-for="(item, index) in listData" :key="index">
            <detail-cell :data-input="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ZHeader from "@/components/zHeader"
import ZLeft from "@/components/zLeft"
import DetailCell from "@/components/detailCell"
import config from "@/utils/config";

export default {
  name: 'ShuZiLuanShengView',
  components: {DetailCell, ZLeft, ZHeader},
  data() {
    return {
      listData: [
        {
          label: '拆解',
          children: [
            {
              label: '飞机涡轮发动机模型',
              type: 2,
              url: config.fuJian + '数字孪生/拆解/飞机涡轮发动机模型.mp4',
              img: config.fuJian + '数字孪生/拆解/飞机涡轮发动机模型.png'
            },
            {
              label: '机器拆解模型三维可视化',
              type: 2,
              url: config.fuJian + '数字孪生/拆解/机器拆解模型三维可视化.mp4',
              img: config.fuJian + '数字孪生/拆解/机器拆解模型三维可视化.png'
            },
            {
              label: '卡车拆解示意图可视化',
              type: 2,
              url: config.fuJian + '数字孪生/拆解/卡车拆解示意图可视化.mp4',
              img: config.fuJian + '数字孪生/拆解/卡车拆解示意图可视化.png'
            },
            {
              label: '数字孪生模型拆解-航空发动机',
              type: 2,
              url: config.fuJian + '数字孪生/拆解/数字孪生模型拆解-航空发动机.mp4',
              img: config.fuJian + '数字孪生/拆解/数字孪生模型拆解-航空发动机.png'
            },
            {
              label: '数字孪生模型拆解-励磁器',
              type: 2,
              url: config.fuJian + '数字孪生/拆解/数字孪生模型拆解-励磁器.mp4',
              img: config.fuJian + '数字孪生/拆解/数字孪生模型拆解-励磁器.png'
            }
          ]
        },
        {
          label: '可视化',
          children: [
            {
              label: '城市白模大场景可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/城市白模大场景可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/城市白模大场景可视化.png'
            },
            {
              label: '地理展览馆漫游可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/地理展览馆漫游可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/地理展览馆漫游可视化.png'
            },
            {
              label: '飞机航天展馆三维可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/飞机航天展馆三维可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/飞机航天展馆三维可视化.png'
            },
            {
              label: '钢材厂内部展示可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/钢材厂内部展示可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/钢材厂内部展示可视化.png'
            },
            {
              label: '古董展示可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/古董展示可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/古董展示可视化.png'
            },
            {
              label: '空间站展示可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/空间站展示可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/空间站展示可视化.png'
            },
            {
              label: '空中飞机加油可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/空中飞机加油可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/空中飞机加油可视化.png'
            },
            {
              label: '跨海大桥可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/跨海大桥可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/跨海大桥可视化.png'
            },
            {
              label: '老姆庙倾斜摄影',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/老姆庙倾斜摄影.mp4',
              img: config.fuJian + '数字孪生/可视化/老姆庙倾斜摄影.png'
            },
            {
              label: '汽车需求调研互动系统',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/汽车需求调研互动系统.mp4',
              img: config.fuJian + '数字孪生/可视化/汽车需求调研互动系统.png'
            },
            {
              label: '人体组织展示可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/人体组织展示可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/人体组织展示可视化.png'
            },
            {
              label: '太空空间站三维可视化1',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/太空空间站三维可视化1.mp4',
              img: config.fuJian + '数字孪生/可视化/太空空间站三维可视化1.png'
            },
            {
              label: '太空空间站三维可视化2',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/太空空间站三维可视化2.mp4',
              img: config.fuJian + '数字孪生/可视化/太空空间站三维可视化2.png'
            },
            {
              label: '太阳系三维可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/太阳系三维可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/太阳系三维可视化.png'
            },
            {
              label: '现代机房模型数字孪生系统',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/现代机房模型数字孪生系统.mp4',
              img: config.fuJian + '数字孪生/可视化/现代机房模型数字孪生系统.png'
            },
            {
              label: '宇宙火箭发射场景三维可视化',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/宇宙火箭发射场景三维可视化.mp4',
              img: config.fuJian + '数字孪生/可视化/宇宙火箭发射场景三维可视化.png'
            },
            {
              label: '智慧农业展馆漫游',
              type: 2,
              url: config.fuJian + '数字孪生/可视化/智慧农业展馆漫游.mp4',
              img: config.fuJian + '数字孪生/可视化/智慧农业展馆漫游.png'
            }
          ]
        },
        {
          label: '数据可视化大屏',
          children: [
            {
              label: '地下停车场智慧监察系统',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/地下停车场智慧监察系统.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/地下停车场智慧监察系统.png'
            },
            {
              label: '国网电力监控运维平台',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/国网电力监控运维平台.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/国网电力监控运维平台.png'
            },
            {
              label: '互联网安全监测平台可视化',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/互联网安全监测平台可视化.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/互联网安全监测平台可视化.png'
            },
            {
              label: '机场智慧管理系统',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/机场智慧管理系统.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/机场智慧管理系统.png'
            },
            {
              label: '机房信息数据可视化',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/机房信息数据可视化.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/机房信息数据可视化.png'
            },
            {
              label: '设备机械零件智慧仓储系统',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/设备机械零件智慧仓储系统.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/设备机械零件智慧仓储系统.png'
            },
            {
              label: '体育馆赛事可视化大屏',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/体育馆赛事可视化大屏.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/体育馆赛事可视化大屏.png'
            },
            {
              label: '网络信息监控可视化大屏',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/网络信息监控可视化大屏.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/网络信息监控可视化大屏.png'
            },
            {
              label: '网络信息链路拓扑监控',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/网络信息链路拓扑监控.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/网络信息链路拓扑监控.png'
            },
            {
              label: '物流园区可视化管理平台',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/物流园区可视化管理平台.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/物流园区可视化管理平台.png'
            },
            {
              label: '月球监测数据大屏',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/月球监测数据大屏.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/月球监测数据大屏.png'
            },
            {
              label: '智慧城市可视化应用管理平台',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/智慧城市可视化应用管理平台.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/智慧城市可视化应用管理平台.png'
            },
            {
              label: '智慧党建展厅',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/智慧党建展厅.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/智慧党建展厅.png'
            },
            {
              label: '智慧港口可视化管理',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/智慧港口可视化管理.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/智慧港口可视化管理.png'
            },
            {
              label: '智慧工厂-养殖系统数据大屏',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/智慧工厂-养殖系统数据大屏.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/智慧工厂-养殖系统数据大屏.png'
            },
            {
              label: '智慧消防可视化数据分析平台',
              type: 2,
              url: config.fuJian + '数字孪生/数据可视化大屏/智慧消防可视化数据分析平台.mp4',
              img: config.fuJian + '数字孪生/数据可视化大屏/智慧消防可视化数据分析平台.png'
            }
          ]
        },
        {
          label: '数字孪生应用场景',
          children: [
            {
              label: '煤矿运输数字孪生',
              type: 2,
              url: config.fuJian + '数字孪生/数字孪生应用场景/煤矿运输数字孪生.mp4',
              img: config.fuJian + '数字孪生/数字孪生应用场景/煤矿运输数字孪生.png'
            },
            {
              label: '农作物种植数字孪生系统演示',
              type: 2,
              url: config.fuJian + '数字孪生/数字孪生应用场景/农作物种植数字孪生系统演示.mp4',
              img: config.fuJian + '数字孪生/数字孪生应用场景/农作物种植数字孪生系统演示.png'
            },
            {
              label: '燃气仓储厂数字孪生系统',
              type: 2,
              url: config.fuJian + '数字孪生/数字孪生应用场景/燃气仓储厂数字孪生系统.mp4',
              img: config.fuJian + '数字孪生/数字孪生应用场景/燃气仓储厂数字孪生系统.png'
            },
            {
              label: '未来街区数字孪生',
              type: 2,
              url: config.fuJian + '数字孪生/数字孪生应用场景/未来街区数字孪生.mp4',
              img: config.fuJian + '数字孪生/数字孪生应用场景/未来街区数字孪生.png'
            },
            {
              label: '现代流水线生产车间数字孪生系统',
              type: 2,
              url: config.fuJian + '数字孪生/数字孪生应用场景/现代流水线生产车间数字孪生系统.mp4',
              img: config.fuJian + '数字孪生/数字孪生应用场景/现代流水线生产车间数字孪生系统.png'
            },
            {
              label: '智慧工厂生产线',
              type: 2,
              url: config.fuJian + '数字孪生/数字孪生应用场景/智慧工厂生产线.mp4',
              img: config.fuJian + '数字孪生/数字孪生应用场景/智慧工厂生产线.png'
            }
          ]
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
.span-txt {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.span-img {
  width: 140px;
  height: 70px;
  object-fit: cover;
  border-radius: 6px;
}

</style>
