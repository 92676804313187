<template>
<!--  <video-player :is-show="showPlayer" :video-url="url" :poster="poster" @onClose="showPlayer = false"/>-->

  <div class="cell">
    <div class="cell-top">
      <span>{{ dataInput.label }}</span>
    </div>
    <div class="cell-row">
      <div
          v-for="(item, index) in dataInput.children"
          :key="index"
          class="cell-row-item"
          @click="_toDetail(item)"
      >
        <span class="cell-row-item-top">{{ item.label }}</span>
        <img :src="item.img" alt="img" class="img-inner"/>

        <div class="cell-btn" @click="_toDetail(item)" @click.stop="false">
          {{ item.type === 1 ? '点击访问' : '点击观看' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VideoPlayer from "@/components/videoPlayer"

export default {
  name: "detailCell",
  // components: {VideoPlayer},
  props: ['dataInput'],
  data() {
    return {
      showPlayer: false,
      url: undefined,
      poster: undefined
    }
  },
  methods: {
    _toDetail(e){
      const url = e.url
      if (e.type === 1) {
        window.open(url, '_blank')
      } else {
        window.open(url, '_blank')
        // this.url = url
        // this.showPlayer = true
        // this.poster = e.img
      }
    }
  }
}
</script>

<style scoped>
.cell {
  width: 96%;
  height: auto;
  margin: 0 0 20px 0;
  padding: 0 2% 0 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.cell-top {
  width: 140px;
  height: 40px;
  background: #323d6e;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, .12);
}

.cell-btn {
  padding: 5px 6px;
  background: #425399;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  border-radius: 4px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, .12);
  margin-bottom: 10px;
}
.cell-btn:hover {
  background: rgba(66, 83, 153, 0.9);
  cursor: pointer;
}
.cell-row {
  width: 98%;
  padding: 0 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.cell-row-item {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 6px;
  margin: 10px 20px;
  padding: 0 20px;
}
.cell-row-item:hover {
  background: #b7b7b7;
  cursor: pointer;
  border-radius: 20px;
}
.cell-row-item:hover .cell-row-item-top{
  color: #323d6e;
}
.cell-row-item-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  height: 40px;
}

.img-inner {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 6px;
}
</style>